import { ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import clsx from 'clsx';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { selectFuseCurrentLayoutConfig, selectToolbarTheme } from 'app/store/fuse/settingsSlice';
import { selectFuseNavbar } from 'app/store/fuse/navbarSlice';
import AdjustFontSize from '../../shared-components/AdjustFontSize';
import FullScreenToggle from '../../shared-components/FullScreenToggle';
import LanguageSwitcher from '../../shared-components/LanguageSwitcher';
import NotificationPanelToggleButton from '../../shared-components/notificationPanel/NotificationPanelToggleButton';
import NavigationShortcuts from '../../shared-components/NavigationShortcuts';
import NavigationSearch from '../../shared-components/NavigationSearch';
import NavbarToggleButton from '../../shared-components/NavbarToggleButton';
import UserMenu from '../../shared-components/UserMenu';
import QuickPanelToggleButton from '../../shared-components/quickPanel/QuickPanelToggleButton';
import ChatPanelToggleButton from '../../shared-components/chatPanel/ChatPanelToggleButton';
import {Route, Link, Routes, useLocation} from 'react-router-dom';

function ToolbarLayout1(props) {
  const config = useSelector(selectFuseCurrentLayoutConfig);
  const navbar = useSelector(selectFuseNavbar);
  const toolbarTheme = useSelector(selectToolbarTheme);
  const location = useLocation();
  console.log('hash', location.hash);
  console.log('pathname', location.pathname);
  console.log('search', location.search);
  var pageTitle = 'Dashboard';
  if(location.pathname.indexOf("/jobs") !== -1){
    pageTitle = "Jobs";
  }
  if(location.pathname.indexOf("/quotes") !== -1){
    pageTitle = "Quotes";
  }
  if(location.pathname.indexOf("/partners") !== -1){
    pageTitle = "Partners";
  }
  if(location.pathname.indexOf("/documents") !== -1){
    pageTitle = "My Documents";
  }
  if(location.pathname.indexOf("/organization") !== -1){
    pageTitle = "Organization";
  }
  if(location.pathname.indexOf("/reports") !== -1){
    pageTitle = "Reports";
  }
  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="fuse-toolbar"
        className={clsx('flex relative z-20 shadow-md top-toolbar', props.className)}
        color="default"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? toolbarTheme.palette.background.dark
              : toolbarTheme.palette.background.dark,
        }}
        position="static"
      >
        <Toolbar className="p-0 min-h-48 md:min-h-64" >
          <div className="w-1/4 flex flex-1 px-16">
            {config.navbar.display && config.navbar.position === 'left' && (
              <>
                {/* <Hidden lgDown>
                  {(config.navbar.style === 'style-3' ||
                    config.navbar.style === 'style-3-dense') && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}

                  {config.navbar.style === 'style-1' && !navbar.open && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}
                </Hidden> */}

                <Hidden lgUp>
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8 white-color" />
                </Hidden>                
              </>
            )}

            {/* <Hidden lgDown>
              <NavigationShortcuts />
            </Hidden> */}            
          </div>
          <div className="w-1/2 items-center px-8 overflow-x-auto white-color text-center">{pageTitle}</div>
          <div className="w-1/4 flex items-center px-8 h-full overflow-x-auto">
            {/* <LanguageSwitcher />

            <AdjustFontSize />

            <FullScreenToggle />

            <NavigationSearch />

            <Hidden lgUp>
              <ChatPanelToggleButton />
            </Hidden>

            <QuickPanelToggleButton /> */}
            
            {/* <NotificationPanelToggleButton />

            <UserMenu /> */}
          </div>

          {config.navbar.display && config.navbar.position === 'right' && (
            <>
              <Hidden lgDown>
                {!navbar.open && <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />}
              </Hidden>

              <Hidden lgUp>
                <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
              </Hidden>
            </>
          )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default memo(ToolbarLayout1);
