import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as Constants from "../configs/constants";

import axios from "axios";

export const createJob = createAsyncThunk(
  "jobDetail/create",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Jobs/createJob", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getQuotesByJob = createAsyncThunk(
  "quotes/listByJob",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Quotes/getQuotes", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getJobDetail = createAsyncThunk(
  "job/detail",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Jobs/getJobDetail", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getQuoteLineItemsByJob = createAsyncThunk(
  "job/quoteLineItems",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "QuoteLineItems/getQuoteLineItemsByJob", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getJobDetailByKey = createAsyncThunk(
  "job/detailByKey",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Jobs/getJobDetailByKey", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getJobDetailByQuoteKey = createAsyncThunk(
  "job/detailByQuoteKey",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Jobs/getJobDetailByQuoteKey", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getQuoteStatusByJob = createAsyncThunk(
  "job/quoteStatus",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Quotes/getQuoteStatusByJob", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateJob = createAsyncThunk(
  "jobDetail/update",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Jobs/updateJob", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getJobProductCatalogDetails = createAsyncThunk(
  "job/productCatalogDetails",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(
          Constants.API_URL + "Jobs/getJobProductCatalogDetails",
          userData,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("jwt_access_token"),
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const approveInstallationDate = createAsyncThunk(
  "job/approveInstallationDate",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Jobs/approveInstallationDate", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const declineInstallationDate = createAsyncThunk(
  "job/declineInstallationDate",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Jobs/declineInstallationDate", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const createQuoteQuestion = createAsyncThunk(
  "jobDetail/questionCreate",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Questions/createJobQuoteQuestion", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getQuoteQuestionsByJob = createAsyncThunk(
  "jobDetail/listOfQuestions",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Questions/getJobQuoteQuestionsByJob", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const deleteQuoteQuestion = createAsyncThunk(
  "jobDetail/deleteQuoteQuestion",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Questions/deleteJobQuoteQuestion", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateQuoteQuestion = createAsyncThunk(
  'jobDetail/updateQuoteQuestion',
  async (data, thunkAPI) => {
    try {
      return await axios.post(Constants.API_URL + 'Questions/updateJobQuoteQuestion', data, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateQuoteQuestionsOrder = createAsyncThunk(
  'jobDetail/updateQuoteQuestionsOrder',
  async (data, thunkAPI) => {
    try {
      const userData = data;
      let getQuoteLineItemsURL = Constants.API_URL + 'Questions/updateJobQuoteQuestionsOrder';
      return await axios.post(getQuoteLineItemsURL, userData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);


export const createJobQuestion = createAsyncThunk(
  "jobDetail/jobQuestionCreate",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Questions/createJobQuestion", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getJobQuestionsByJob = createAsyncThunk(
  "jobDetail/listOfJobQuestions",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Questions/getJobQuestionsByJob", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const deleteJobQuestion = createAsyncThunk(
  "jobDetail/deleteJobQuestion",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Questions/deleteJobQuestion", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateJobQuestion = createAsyncThunk(
  'jobDetail/updateJobQuestion',
  async (data, thunkAPI) => {
    try {
      return await axios.post(Constants.API_URL + 'Questions/updateJobQuestion', data, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateJobQuestionsOrder = createAsyncThunk(
  'jobDetail/updateJobQuestionsOrder',
  async (data, thunkAPI) => {
    try {
      const userData = data;
      let getQuoteLineItemsURL = Constants.API_URL + 'Questions/updateJobQuestionsOrder';
      return await axios.post(getQuoteLineItemsURL, userData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateQuoteDueDate = createAsyncThunk(
  "quote/updateQuoteDueDate",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Quotes/updateQuoteDueDate", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token")
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            if(response.data){
              response.data.date = userData.date;
              return response.data;
            }
            return "success";
            
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const saveJobQuestions = createAsyncThunk(
  "jobDetail/saveJobQuestionsData",
  async (data, thunkAPI) => {
    try {
      const userData = data;
      return await axios
        .post(Constants.API_URL + "Questions/saveJobQuestions", userData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
            "Content-Type" : "multipart/form-data",
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const jobDetailSlice = createSlice({
  name: "jobDetail",
  initialState: {
    isJobDetailFetching: false,
    isJobDetailSuccess: false,
    isJobDetailError: false,
    jobDetailErrorMessage: "",
    jobDetails: {
      log_account_type: "",
    },
    jobAttachments: [],
    jobQuotes: [],
    jobId: "",
    requestFrom: "",
    accountType: "",
    isQuoteListFetching: false,
    isQuoteListSuccess: false,
    isQuoteListError: false,
    quoteListErrorMessage: "",
    quotesListByJob: [],
    isQuoteStatusFetching: false,
    isQuoteStatusSuccess: false,
    isQuoteStatusSuccessful: false,
    isQuoteStatusError: false,
    quoteStatusByJob: [],
    quoteStatusErrorMessage: "",
    isUpdateJobFetching: false,
    isUpdateJobSuccess: false,
    isUpdateJobError: false,
    updateJobErrorMessage: "",

    isApproveInstallationDateFetching: false,
    isApproveInstallationDateSuccess: false,
    isApproveInstallationDateError: false,
    approveInstallationDateErrorMessage: "",

    isProductCatalogDetailsFetching: false,
    isProductCatalogDetailsSuccess: false,
    isProductCatalogDetailsError: false,
    productCatalogDetailsErrorMessage: "",

    isDeclineInstallationDateFetching: false,
    isDeclineInstallationDateSuccess: false,
    isDeclineInstallationDateError: false,
    declineInstallationDateErrorMessage: "",

    isGetQuoteLineItemsByJobFetching: false,
    isGetQuoteLineItemsByJobSuccess: false,
    isGetQuoteLineItemsByJobError: false,
    getQuoteLineItemsByJobErrorMessage: "",

    productCatalogItems: [],
    quoteLineItems: [],
    jobQuestions: [],
    quoteQuestions: [],

    isCreateQuestionFetching: false,
    isCreateQuestionSuccess: false,
    isCreateQuestionError: false,
    createQuestionErrorMessage: '',

    isUpdateQuestionsOrderFetching: false,
    isUpdateQuestionsOrderSuccess: false,
    isUpdateQuestionsOrderError: false,
    updateQuestionsOrderErrorMessage: '',

    isUpdateQuestionFetching: false,
    isUpdateQuestionSuccess: false,
    isUpdateQuestionError: false,
    updateQuestionErrorMessage: '',

    isDeleteQuestionFetching: false,
    isDeleteQuestionSuccess: false,
    isDeleteQuestionError: false,
    deleteQuestionErrorMessage: '',

    isGetQuestionsFetching: false,
    isGetQuestionsSuccess: false,
    isGetQuestionsError: false,
    getQuestionsErrorMessage: '',

    isCreateJobQuestionFetching: false,
    isCreateJobQuestionSuccess: false,
    isCreateJobQuestionError: false,
    createJobQuestionErrorMessage: '',

    isUpdateJobQuestionsOrderFetching: false,
    isUpdateJobQuestionsOrderSuccess: false,
    isUpdateJobQuestionsOrderError: false,
    updateJobQuestionsOrderErrorMessage: '',

    isUpdateJobQuestionFetching: false,
    isUpdateJobQuestionSuccess: false,
    isUpdateJobQuestionError: false,
    updateJobQuestionErrorMessage: '',

    isDeleteJobQuestionFetching: false,
    isDeleteJobQuestionSuccess: false,
    isDeleteJobQuestionError: false,
    deleteJobQuestionErrorMessage: '',

    isGetJobQuestionsFetching: false,
    isGetJobQuestionsSuccess: false,
    isGetJobQuestionsError: false,
    getJobQuestionsErrorMessage: '',

    isUpdateQuoteDueDateFetching: false,
    isUpdateQuoteDueDateSuccess: false,
    isUpdateQuoteDueDateError: false,
    updateQuoteDueDateErrorMessage: '',

    isSaveJobQuestionsFetching: false,
    isSaveJobQuestionsSuccess: false,
    isSaveJobQuestionsError: false,
    saveJobQuestionsErrorMessage: '',
  },
  reducers: {
    clearJobDetailState: (state) => {
      state.isJobDetailFetching = false;
      state.isJobDetailSuccess = false;
      state.isJobDetailError = false;
      state.isQuoteStatusSuccess = false;
      state.isQuoteStatusSuccessful = false;
      state.jobDetails = [];
      state.jobQuotes = [];
      state.jobAttachments = [];
      state.productCatalogItems = [];
      state.quoteLineItems = [];
      state.jobQuestions = [];
      state.quoteQuestions = [];
      state.jobId = "";
      state.requestFrom = "";
      state.accountType = "";
      return state;
    },
    clearJobProductCatalogDetails: (state) => {
      state.isProductCatalogDetailsError = false;
      state.isProductCatalogDetailsFetching = false;
      state.isProductCatalogDetailsSuccess = false;
      state.productCatalogDetailsErrorMessage = "";
    },
    clearDeclineInstallationDateState: (state) => {
      state.isDeclineInstallationDateError = false;
      state.isDeclineInstallationDateFetching = false;
      state.isDeclineInstallationDateSuccess = false;
      state.declineInstallationDateErrorMessage = "";
    },
    clearApproveInstallationDateState: (state) => {
      state.isApproveInstallationDateError = false;
      state.isApproveInstallationDateFetching = false;
      state.isApproveInstallationDateSuccess = false;
      state.approveInstallationDateErrorMessage = "";
    },
    clearGetQuoteLineItemsByJobState: (state) => {
      state.isGetQuoteLineItemsByJobError = false;
      state.isGetQuoteLineItemsByJobFetching = false;
      state.isGetQuoteLineItemsByJobSuccess = false;
      state.getQuoteLineItemsByJobErrorMessage = "";
    },
    clearQuotesListState: (state) => {
      state.isQuoteListFetching = false;
      state.isQuoteListSuccess = false;
      state.isQuoteListError = false;
      state.quotesListByJob = [];
      return state;
    },
    clearUpdateJobState: (state) => {
      state.isUpdateJobError = false;
      state.isUpdateJobFetching = false;
      state.isUpdateJobSuccess = false;
      state.updateJobErrorMessage = "";
      return state;
    },
    clearQuoteStatusByJobState: (state) => {
      state.isQuoteStatusFetching = false;
      state.isQuoteStatusSuccess = false;
      state.isQuoteStatusError = false;
      state.quoteStatusByJob = [];
      state.quoteStatusErrorMessage = "";
      return state;
    },
    updateJobAttachments: (state, payload) => {
      state.jobAttachments = payload.payload || [];
    },
    updateJobDetailId: (state, payload) => {
      if (payload.payload.jobId) {
        state.jobId = payload.payload.jobId;
      }
      if (payload.payload.requestFrom) {
        state.requestFrom = payload.payload.requestFrom;
      }
      if (payload.payload.accountType) {
        state.accountType = payload.payload.accountType;
      }
    },
    clearCreateQuestionState: (state) => {
      state.isCreateQuestionError = false;
      state.isCreateQuestionSuccess = false;
      state.isCreateQuestionFetching = false;
      state.createQuestionErrorMessage = "";
      return state;
    },
    clearUpdateQuestionsOrderState: (state) => {
      state.isUpdateQuestionsOrderError = false;
      state.isUpdateQuestionsOrderSuccess = false;
      state.isUpdateQuestionsOrderFetching = false;
      state.updateQuestionsOrderErrorMessage = "";
      return state;
    },
    clearUpdateQuestionState: (state) => {
      state.isUpdateQuestionError = false;
      state.isUpdateQuestionSuccess = false;
      state.isUpdateQuestionFetching = false;
      state.updateQuestionErrorMessage = "";
      return state;
    },
    clearDeleteQuestionState: (state) => {
      state.isDeleteQuestionError = false;
      state.isDeleteQuestionSuccess = false;
      state.isDeleteQuestionFetching = false;
      state.deleteQuestionErrorMessage = "";
      return state;
    },
    clearGetQuestionsState: (state) => {
      state.isGetQuestionsError = false;
      state.isGetQuestionsSuccess = false;
      state.isGetQuestionsFetching = false;
      state.getQuestionsErrorMessage = "";
      return state;
    },
    clearCreateJobQuestionState: (state) => {
      state.isCreateJobQuestionError = false;
      state.isCreateJobQuestionSuccess = false;
      state.isCreateJobQuestionFetching = false;
      state.createJobQuestionErrorMessage = "";
      return state;
    },
    clearUpdateJobQuestionsOrderState: (state) => {
      state.isUpdateJobQuestionsOrderError = false;
      state.isUpdateJobQuestionsOrderSuccess = false;
      state.isUpdateJobQuestionsOrderFetching = false;
      state.updateJobQuestionsOrderErrorMessage = "";
      return state;
    },
    clearUpdateJobQuestionState: (state) => {
      state.isUpdateJobQuestionError = false;
      state.isUpdateJobQuestionSuccess = false;
      state.isUpdateJobQuestionFetching = false;
      state.updateJobQuestionErrorMessage = "";
      return state;
    },
    clearDeleteJobQuestionState: (state) => {
      state.isDeleteJobQuestionError = false;
      state.isDeleteJobQuestionSuccess = false;
      state.isDeleteJobQuestionFetching = false;
      state.deleteJobQuestionErrorMessage = "";
      return state;
    },
    clearGetJobQuestionsState: (state) => {
      state.isGetJobQuestionsError = false;
      state.isGetJobQuestionsSuccess = false;
      state.isGetJobQuestionsFetching = false;
      state.getJobQuestionsErrorMessage = "";
      return state;
    },
    clearUpdateQuoteDueDateState: (state) => {
      state.isUpdateQuoteDueDateError = false;
      state.isUpdateQuoteDueDateFetching = false;
      state.isUpdateQuoteDueDateSuccess = false;
      state.updateQuoteDueDateErrorMessage = "";
      return state;
    },
    clearSaveJobQuestionsState: (state) => {
      state.isSaveJobQuestionsError = false;
      state.isSaveJobQuestionsFetching = false;
      state.isSaveJobQuestionsSuccess = false;
      state.saveJobQuestionsErrorMessage = "";
      return state;
    }
  },
  extraReducers: {
    [createJob.fulfilled]: (state, { payload }) => {
      state.isJobFetching = false;
      state.isJobSuccess = true;
      return state;
    },
    [createJob.rejected]: (state, { payload }) => {
      state.isJobFetching = false;
      state.isJobError = true;
      state.jobErrorMessage = payload.error_description
        ? payload.error_description
        : "Invalid Credentials. Please try again!";
    },
    [createJob.pending]: (state) => {
      state.isJobFetching = true;
    },
    [updateJob.fulfilled]: (state, { payload }) => {
      state.isUpdateJobFetching = false;
      if (payload.status == "error") {
        state.updateJobErrorMessage =
          payload.error || "Unable to update job. Please try again.";
        state.isUpdateJobError = true;
      } else {
        state.isUpdateJobSuccess = true;
      }
      return state;
    },
    [updateJob.rejected]: (state, { payload }) => {
      state.isUpdateJobFetching = false;
      state.isUpdateJobError = true;
      state.updateJobErrorMessage = payload.error_description
        ? payload.error_description
        : "Invalid Credentials. Please try again!";
    },
    [updateJob.pending]: (state) => {
      state.isUpdateJobFetching = true;
    },
    [approveInstallationDate.fulfilled]: (state, { payload }) => {
      state.isApproveInstallationDateFetching = false;
      if (payload.status == "error") {
        state.approveInstallationDateErrorMessage =
          payload.error || "Unable to update job. Please try again.";
        state.isApproveInstallationDateError = true;
      } else {
        state.isApproveInstallationDateSuccess = true;
      }
      return state;
    },
    [approveInstallationDate.rejected]: (state, { payload }) => {
      state.isApproveInstallationDateFetching = false;
      state.isApproveInstallationDateError = true;
      state.approveInstallationDateErrorMessage = payload.error_description
        ? payload.error_description
        : "Invalid Credentials. Please try again!";
    },
    [approveInstallationDate.pending]: (state) => {
      state.isApproveInstallationDateFetching = true;
    },
    [declineInstallationDate.fulfilled]: (state, { payload }) => {
      state.isDeclineInstallationDateFetching = false;
      if (payload.status == "error") {
        state.declineInstallationDateErrorMessage =
          payload.error || "Unable to update job. Please try again.";
        state.isDeclineInstallationDateError = true;
      } else {
        state.isDeclineInstallationDateSuccess = true;
      }
      return state;
    },
    [declineInstallationDate.rejected]: (state, { payload }) => {
      state.isDeclineInstallationDateFetching = false;
      state.isDeclineInstallationDateError = true;
      state.declineInstallationDateErrorMessage = payload.error_description
        ? payload.error_description
        : "Invalid Credentials. Please try again!";
    },
    [declineInstallationDate.pending]: (state) => {
      state.isDeclineInstallationDateFetching = true;
    },
    [getJobDetail.fulfilled]: (state, { payload }) => {
      state.isJobDetailFetching = false;
      state.jobDetails = payload;
      state.isJobDetailSuccess = true;
      return state;
    },
    [getJobDetail.rejected]: (state, { payload }) => {
      state.isJobDetailFetching = false;
      state.isJobDetailError = true;
      state.jobDetailErrorMessage = payload.error_description
        ? payload.error_description
        : "Invalid Credentials. Please try again!";
    },
    [getJobDetail.pending]: (state) => {
      state.isJobDetailFetching = true;
    },
    [getQuoteLineItemsByJob.fulfilled]: (state, { payload }) => {
      state.isGetQuoteLineItemsByJobFetching = false;
      state.quoteLineItems = payload && payload.data || [];
      state.isGetQuoteLineItemsByJobSuccess = true;
      return state;
    },
    [getQuoteLineItemsByJob.rejected]: (state, { payload }) => {
      state.isGetQuoteLineItemsByJobFetching = false;
      state.isGetQuoteLineItemsByJobError = true;
      state.getQuoteLineItemsByJobErrorMessage = payload.error_description
        ? payload.error_description
        : "Invalid Credentials. Please try again!";
    },
    [getQuoteLineItemsByJob.pending]: (state) => {
      state.isGetQuoteLineItemsByJobFetching = true;
    },
    [getJobDetailByKey.fulfilled]: (state, { payload }) => {
      state.isJobDetailFetching = false;
      if (payload.status == "error") {
        state.isJobDetailError = true;
        state.jobDetailErrorMessage = payload.error
          ? payload.error
          : "Invalid Job number. Please try again";
      } else {
        state.jobDetails = payload.data;
        state.jobAttachments = (payload && payload.attachments) || [];
        state.jobId = payload.data.job_id;
        state.productCatalogItems = payload.catalog_details || [];
        state.isJobDetailSuccess = true;
        state.jobQuotes = payload && payload.quotes || [];
        state.quoteLineItems = payload && payload.quote_line_items || [];
        state.quoteQuestions = payload && payload.quote_questions || []
        state.jobQuestions = payload && payload.job_questions || []
      }
      return state;
    },
    [getJobDetailByKey.rejected]: (state, { payload }) => {
      state.isJobDetailFetching = false;
      state.isJobDetailError = true;
      state.jobDetailErrorMessage = payload.error_description
        ? payload.error_description
        : "Invalid Credentials. Please try again!";
    },
    [getJobDetailByKey.pending]: (state) => {
      state.isJobDetailFetching = true;
    },
    [getJobDetailByQuoteKey.fulfilled]: (state, { payload }) => {
      state.isJobDetailFetching = false;
      if (payload.status == "error") {
        state.isJobDetailError = true;
        state.jobDetailErrorMessage = payload.error
          ? payload.error
          : "Invalid Job number. Please try again";
      } else {
        state.jobDetails = payload.data;
        state.jobId = payload.data.job_id;
        state.isJobDetailSuccess = true;
      }
      return state;
    },
    [getJobDetailByQuoteKey.rejected]: (state, { payload }) => {
      state.isJobDetailFetching = false;
      state.isJobDetailError = true;
      state.jobDetailErrorMessage = payload.error_description
        ? payload.error_description
        : "Invalid Credentials. Please try again!";
    },
    [getJobDetailByQuoteKey.pending]: (state) => {
      state.isJobDetailFetching = true;
    },
    [getQuotesByJob.fulfilled]: (state, { payload }) => {
      state.isQuoteListFetching = false;
      state.quotesListByJob = payload;
      state.isQuoteListSuccess = true;
      return state;
    },
    [getQuotesByJob.rejected]: (state, { payload }) => {
      state.isQuoteListFetching = false;
      state.isQuoteListError = true;
      state.quoteListErrorMessage = payload.error_description
        ? payload.error_description
        : "Invalid Credentials. Please try again!";
    },
    [getQuotesByJob.pending]: (state) => {
      state.isQuoteListFetching = true;
    },
    [getQuoteStatusByJob.fulfilled]: (state, { payload }) => {
      console.log("QUOTE SLICE TRUE");
      state.isQuoteStatusFetching = false;
      state.quoteStatusByJob = payload;
      state.isQuoteStatusSuccess = true;
      state.isQuoteStatusSuccessful = true;
      return state;
    },
    [getQuoteStatusByJob.rejected]: (state, { payload }) => {
      state.isQuoteStatusFetching = false;
      state.isQuoteStatusError = true;
      state.quoteStatusErrorMessage = payload.error_description
        ? payload.error_description
        : "Invalid Credentials. Please try again!";
    },
    [getQuoteStatusByJob.pending]: (state) => {
      state.isQuoteStatusFetching = true;
      state.isQuoteStatusSuccess = false;
    },
    [getJobProductCatalogDetails.fulfilled]: (state, { payload }) => {
      state.isProductCatalogDetailsFetching = false;
      state.productCatalogItems = payload;
      state.isProductCatalogDetailsSuccess = true;
      return state;
    },
    [getJobProductCatalogDetails.rejected]: (state, { payload }) => {
      state.isProductCatalogDetailsFetching = false;
      state.isProductCatalogDetailsError = true;
      state.productCatalogDetailsErrorMessage = payload.error_description
        ? payload.error_description
        : "Invalid Credentials. Please try again!";
    },
    [getJobProductCatalogDetails.pending]: (state) => {
      state.isProductCatalogDetailsFetching = true;
    },

    [createQuoteQuestion.fulfilled]: (state, { payload }) => {
      state.isCreateQuestionFetching = false;
      state.isCreateQuestionSuccess = true;
    },
    [createQuoteQuestion.pending]: (state) => {
      state.isCreateQuestionFetching = true;
    },
    [createQuoteQuestion.rejected]: (state, { payload }) => {
      state.isCreateQuestionFetching = false;
      state.isCreateQuestionError = true;
      state.createQuestionErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },
    [updateQuoteQuestion.fulfilled]: (state, { payload }) => {
      state.isUpdateQuestionFetching = false;
      state.isUpdateQuestionSuccess = true;
    },
    [updateQuoteQuestion.pending]: (state) => {
      state.isUpdateQuestionFetching = true;
    },
    [updateQuoteQuestion.rejected]: (state, { payload }) => {
      state.isUpdateQuestionFetching = false;
      state.isUpdateQuestionError = true;
      state.updateQuestionErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },
    [deleteQuoteQuestion.fulfilled]: (state, { payload }) => {
      state.isDeleteQuestionFetching = false;
      state.isDeleteQuestionSuccess = true;
    },
    [deleteQuoteQuestion.pending]: (state) => {
      state.isDeleteQuestionFetching = true;
    },
    [deleteQuoteQuestion.rejected]: (state, { payload }) => {
      state.isDeleteQuestionFetching = false;
      state.isDeleteQuestionError = true;
      state.deleteQuestionErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },
    [updateQuoteQuestionsOrder.fulfilled]: (state, { payload }) => {
      state.isUpdateQuestionsOrderFetching = false;
      state.isUpdateQuestionsOrderSuccess = true;
    },
    [updateQuoteQuestionsOrder.pending]: (state) => {
      state.isUpdateQuestionsOrderFetching = true;
    },
    [updateQuoteQuestionsOrder.rejected]: (state, { payload }) => {
      state.isUpdateQuestionsOrderFetching = false;
      state.isUpdateQuestionsOrderError = true;
      state.updateQuestionsOrderErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },
    [getQuoteQuestionsByJob.fulfilled]: (state, { payload }) => {
      state.isGetQuestionsFetching = false;
      state.quoteQuestions = payload && payload.data || [];
      state.isGetQuestionsSuccess = true;
      return state;
    },
    [getQuoteQuestionsByJob.rejected]: (state, { payload }) => {
      state.isGetQuestionsFetching = false;
      state.isGetQuestionsError = true;
      state.getQuestionsErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [getQuoteQuestionsByJob.pending]: (state) => {
      state.isGetQuestionsFetching = true;
    },
    [createJobQuestion.fulfilled]: (state, { payload }) => {
      state.isCreateJobQuestionFetching = false;
      state.isCreateJobQuestionSuccess = true;
    },
    [createJobQuestion.pending]: (state) => {
      state.isCreateJobQuestionFetching = true;
    },
    [createJobQuestion.rejected]: (state, { payload }) => {
      state.isCreateJobQuestionFetching = false;
      state.isCreateJobQuestionError = true;
      state.createJobQuestionErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },
    [updateJobQuestion.fulfilled]: (state, { payload }) => {
      state.isUpdateJobQuestionFetching = false;
      state.isUpdateJobQuestionSuccess = true;
    },
    [updateJobQuestion.pending]: (state) => {
      state.isUpdateJobQuestionFetching = true;
    },
    [updateJobQuestion.rejected]: (state, { payload }) => {
      state.isUpdateJobQuestionFetching = false;
      state.isUpdateJobQuestionError = true;
      state.updateJobQuestionErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },
    [deleteJobQuestion.fulfilled]: (state, { payload }) => {
      state.isDeleteJobQuestionFetching = false;
      state.isDeleteJobQuestionSuccess = true;
    },
    [deleteJobQuestion.pending]: (state) => {
      state.isDeleteJobQuestionFetching = true;
    },
    [deleteJobQuestion.rejected]: (state, { payload }) => {
      state.isDeleteJobQuestionFetching = false;
      state.isDeleteJobQuestionError = true;
      state.deleteJobQuestionErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },
    [updateJobQuestionsOrder.fulfilled]: (state, { payload }) => {
      state.isUpdateJobQuestionsOrderFetching = false;
      state.isUpdateJobQuestionsOrderSuccess = true;
    },
    [updateJobQuestionsOrder.pending]: (state) => {
      state.isUpdateJobQuestionsOrderFetching = true;
    },
    [updateJobQuestionsOrder.rejected]: (state, { payload }) => {
      state.isUpdateJobQuestionsOrderFetching = false;
      state.isUpdateJobQuestionsOrderError = true;
      state.updateJobQuestionsOrderErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },
    [getJobQuestionsByJob.fulfilled]: (state, { payload }) => {
      state.isGetJobQuestionsFetching = false;
      state.jobQuestions = payload && payload.data || [];
      state.isGetJobQuestionsSuccess = true;
      return state;
    },
    [getJobQuestionsByJob.rejected]: (state, { payload }) => {
      state.isGetJobQuestionsFetching = false;
      state.isGetJobQuestionsError = true;
      state.getJobQuestionsErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [getJobQuestionsByJob.pending]: (state) => {
      state.isGetJobQuestionsFetching = true;
    },
    [updateQuoteDueDate.fulfilled]: (state, { payload }) => {
      console.log(payload);
      state.quoteStatusByJob.quote_due_date = payload.date;
      state.isUpdateQuoteDueDateFetching = false;
      state.isUpdateQuoteDueDateSuccess = true;
      return state;
    },
    [updateQuoteDueDate.rejected]: (state, { payload }) => {
      state.isUpdateQuoteDueDateFetching = false;
      state.isUpdateQuoteDueDateSuccess = true;
      state.updateQuoteDueDateErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [updateQuoteDueDate.pending]: (state) => {
      state.isUpdateQuoteDueDateFetching = true;
    },
    [saveJobQuestions.fulfilled]: (state, { payload }) => {
      state.isSaveJobQuestionsFetching = false;
      state.isSaveJobQuestionsSuccess = true;
      return state;
    },
    [saveJobQuestions.rejected]: (state, { payload }) => {
      state.isSaveJobQuestionsFetching = false;
      state.isSaveJobQuestionsSuccess = true;
      state.saveJobQuestionsErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [saveJobQuestions.pending]: (state) => {
      state.isSaveJobQuestionsFetching = true;
    }
  },
});

export const {
  clearJobDetailState,
  updateJobDetailId,
  clearApproveInstallationDateState,
  clearDeclineInstallationDateState,
  clearQuotesListState,
  clearUpdateJobState,
  updateJobAttachments,
  clearJobProductCatalogDetails,
  clearQuoteStatusByJobState,
  clearGetQuoteLineItemsByJobState,
  clearCreateQuestionState,
  clearDeleteQuestionState,
  clearGetQuestionsState,
  clearUpdateQuestionState,
  clearUpdateQuestionsOrderState,
  clearUpdateQuoteDueDateState,
  clearCreateJobQuestionState,
  clearDeleteJobQuestionState,
  clearGetJobQuestionsState,
  clearUpdateJobQuestionState,
  clearUpdateJobQuestionsOrderState,
  clearSaveJobQuestionsState
} = jobDetailSlice.actions;

export const jobDetailSelector = (state) => state.jobDetail;
export const quotesListSelector = (state) =>
  (state.jobDetail && state.jobDetail.quotesListByJob) || [];

export default jobDetailSlice.reducer;
