import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Constants from '../configs/constants';

import axios from 'axios';

export const createTeam = createAsyncThunk(
  'team/create',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Users/createTeamAccount', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const deleteTeam = createAsyncThunk(
  'team/delete',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Users/deleteTeamAccount', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getTeam = createAsyncThunk(
  'team/list',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Users/getTeamMembers', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getRoofingProfileSettings = createAsyncThunk(
  'account/profile/list',
  async ( data, thunkAPI) => {
    try {
      const userData = data;
        return await axios.post(Constants.API_URL+'Accounts/getRoofingAccountSettings', userData, {
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem('jwt_access_token')
            }
        })
        .then(function(response){
          if (response.status === 200) {
              return response.data;
          } else {
              return thunkAPI.rejectWithValue(response);
          }
        })
    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const addProfileSettings = createAsyncThunk(
  "account/profile/save",
  async (data, thunkAPI) => {
    try {
      const accountData = data;
      return await axios
        .post(Constants.API_URL + "Accounts/saveRoofingProfileSettings", accountData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt_access_token"),
            "Content-Type" : "multipart/form-data",
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return thunkAPI.rejectWithValue(response);
          }
        });
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const organizationSlice = createSlice({
  name: 'organization',
  initialState: {
    isTeamFetching: false,
    isTeamSuccess: false,
    isTeamError: false,
    teamErrorMessage: '',
    isCreateTeamFetching: false,
    isCreateTeamSuccess: false,
    isCreateTeamError: false,
    createTeamErrorMessage: '',
    isDeleteTeamFetching: false,
    isDeleteTeamSuccess: false,
    isDeleteTeamError: false,
    deleteTeamErrorMessage: '',

    isSaveProfileSettingsFetching: false,
    isSaveProfileSettingsSuccess: false,
    isSaveProfileSettingsError: false,
    saveProfileSettingsErrorMessage: '',

    isGetProfileSettingsFetching: false,
    isGetProfileSettingsSuccess: false,
    isGetProfileSettingsError: false,
    getProfileSettingsErrorMessage: '',

    teamList:[],
    teamId:"",
    roofingProfileSettings:[]
  },
  reducers: {
    clearCreateTeamState: (state) => {
      state.isCreateTeamError = false;
      state.isCreateTeamSuccess = false;
      state.isCreateTeamFetching = false;
      state.createTeamErrorMessage = "";
      return state;
    },
    clearDeleteTeamState: (state) => {
      state.isDeleteTeamError = false;
      state.isDeleteTeamSuccess = false;
      state.isDeleteTeamFetching = false;
      state.deleteTeamErrorMessage = "";
      return state;
    },
    clearSaveProfileSettingsState: (state) => {
      state.isSaveProfileSettingsError = false;
      state.isSaveProfileSettingsSuccess = false;
      state.isSaveProfileSettingsFetching = false;
      state.saveProfileSettingsErrorMessage = "";
      return state;
    },
    clearGetProfileSettingsState: (state) => {
      state.isGetProfileSettingsError = false;
      state.isGetProfileSettingsSuccess = false;
      state.isGetProfileSettingsFetching = false;
      state.getProfileSettingsErrorMessage = "";
      return state;
    },
    clearFullGetRoofingProfileSettingsState: (state) => {
      state.isGetProfileSettingsError = false;
      state.isGetProfileSettingsSuccess = false;
      state.isGetProfileSettingsFetching = false;
      state.getProfileSettingsErrorMessage = "";
      state.roofingProfileSettings = [];
      return state;
    },
    clearTeamState: (state) => {
      state.isTeamError = false;
      state.isTeamSuccess = false;
      state.isTeamFetching = false;
      state.teamList=[];
      return state;
    },
    updateTeamDetailId:(state, payload) =>{
      if(payload.payload.teamId){
        state.teamId = payload.payload.teamId;
      }
    }
  },
  extraReducers: {
    [createTeam.fulfilled]: (state, { payload }) => {
      state.isCreateTeamFetching = false;
      if(payload.status == 'error'){
        state.isCreateTeamError = true;
        state.createTeamErrorMessage = payload.error || "Unable to add team member. Please try again.";
      }
      else{
        state.isCreateTeamSuccess = true;
      }      
    },
    [createTeam.pending]: (state) => {
      state.isCreateTeamFetching = true;
    },
    [createTeam.rejected]: (state, { payload }) => {
      state.isCreateTeamFetching = false;
      state.isCreateTeamError = true;
      state.createAccountErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },
    [deleteTeam.fulfilled]: (state, { payload }) => {
      console.log('payload', payload);
      state.isDeleteTeamFetching = false;
      if(payload.status == 'error'){
        state.isDeleteTeamError = true;
        state.deleteTeamErrorMessage = payload.error || "Unable to add team member. Please try again.";
      }
      else{
        state.isDeleteTeamSuccess = true;
      }      
    },
    [deleteTeam.pending]: (state) => {
      state.isDeleteTeamFetching = true;
    },
    [deleteTeam.rejected]: (state, { payload }) => {
      state.isDeleteTeamFetching = false;
      state.isDeleteTeamError = true;
      state.deleteTeamErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },
    [getTeam.fulfilled]: (state, { payload }) => {
      state.isTeamFetching = false;
      state.teamList = payload;
      state.isTeamSuccess = true;
      return state;
    },
    [getTeam.rejected]: (state, { payload }) => {
      state.isTeamFetching = false;
      state.isTeamError = true;
      state.teamErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
    },
    [getTeam.pending]: (state) => {
      state.isTeamFetching = true;
    },  
    [addProfileSettings.fulfilled]: (state, { payload }) => {
      state.isSaveProfileSettingsFetching = false;
      if(payload.status == 'error'){
        state.isSaveProfileSettingsError = true;
        state.saveProfileSettingsErrorMessage = payload.error || "Unable to add team member. Please try again.";
      }
      else{
        state.isSaveProfileSettingsSuccess = true;
      }      
    },
    [addProfileSettings.pending]: (state) => {
      state.isSaveProfileSettingsFetching = true;
    },
    [addProfileSettings.rejected]: (state, { payload }) => {
      state.isSaveProfileSettingsFetching = false;
      state.isSaveProfileSettingsError = true;
      state.saveProfileSettingsErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },
    [getRoofingProfileSettings.fulfilled]: (state, { payload }) => {
      state.isGetProfileSettingsFetching = false;
      if(payload.status == 'error'){
        state.isgetProfileSettingsError = true;
        state.getProfileSettingsErrorMessage = payload.error || "Unable to add team member. Please try again.";
      }
      else{
        state.isGetProfileSettingsSuccess = true;
        state.roofingProfileSettings= payload && payload.data || []
      }      
    },
    [getRoofingProfileSettings.pending]: (state) => {
      state.isGetProfileSettingsFetching = true;
    },
    [getRoofingProfileSettings.rejected]: (state, { payload }) => {
      state.isGetProfileSettingsFetching = false;
      state.isGetProfileSettingsError = true;
      state.getProfileSettingsErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
    },  
  },
});

export const { clearTeamState, clearFullGetRoofingProfileSettingsState, clearCreateTeamState, updateTeamDetailId, clearDeleteTeamState, clearGetProfileSettingsState, clearSaveProfileSettingsState } = organizationSlice.actions;

export const organizationSelector = (state) => state.organization;

export default organizationSlice.reducer;
