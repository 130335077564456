import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Constants from '../configs/constants';

import axios from 'axios';

export const createQuestion = createAsyncThunk(
    'questions/createJobQuoteWorkTypeQuestion',
    async (data, thunkAPI) => {
        try {
            return await axios.post(Constants.API_URL + 'Questions/createJobQuoteWorkTypeQuestion', data, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return thunkAPI.rejectWithValue(response);
                    }
                })
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getWorkTypeQuestions = createAsyncThunk(
    'questions/getJobQuoteWorkTypeQuestions',
    async (data, thunkAPI) => {
        try {
            const userData = data;
            let getQuoteLineItemsURL = Constants.API_URL + 'Questions/getJobQuoteWorkTypeQuestions';
            return await axios.post(getQuoteLineItemsURL, userData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return thunkAPI.rejectWithValue(response);
                    }
                })
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateQuestionsOrder = createAsyncThunk(
    'questions/updateJobQuoteWorkTypeQuestionsOrder',
    async (data, thunkAPI) => {
        try {
            const userData = data;
            let getQuoteLineItemsURL = Constants.API_URL + 'Questions/updateJobQuoteWorkTypeQuestionsOrder';
            return await axios.post(getQuoteLineItemsURL, userData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return thunkAPI.rejectWithValue(response);
                    }
                })
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const deleteQuestion = createAsyncThunk(
    'questions/deleteJobQuoteWorkTypeQuestion',
    async (data, thunkAPI) => {
        try {
            return await axios.post(Constants.API_URL + 'Questions/deleteJobQuoteWorkTypeQuestion', data, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return thunkAPI.rejectWithValue(response);
                    }
                })
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const updateQuestion = createAsyncThunk(
    'questions/updateJobQuoteWorkTypeQuestion',
    async (data, thunkAPI) => {
        try {
            return await axios.post(Constants.API_URL + 'Questions/updateJobQuoteWorkTypeQuestion', data, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt_access_token')
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return thunkAPI.rejectWithValue(response);
                    }
                })
        } catch (e) {
            console.log('Error', e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const questionsSlice = createSlice({
    name: 'questions',
    initialState: {
        isGetWorkTypeQuestionsFetching: false,
        isGetWorkTypeQuestionsSuccess: false,
        isGetWorkTypeQuestionsError: false,
        getWorkTypeQuestionsErrorMessage: '',
        isCreateQuestionFetching: false,
        isCreateQuestionSuccess: false,
        isCreateQuestionError: false,
        createQuestionErrorMessage: '',
        workTypeQuestionsList: [],

        isUpdateQuestionsOrderFetching: false,
        isUpdateQuestionsOrderSuccess: false,
        isUpdateQuestionsOrderError: false,
        updateQuestionsOrderErrorMessage: '',

        isUpdateQuestionFetching: false,
        isUpdateQuestionSuccess: false,
        isUpdateQuestionError: false,
        updateQuestionErrorMessage: '',

        isDeleteQuestionFetching: false,
        isDeleteQuestionSuccess: false,
        isDeleteQuestionError: false,
        deleteQuestionErrorMessage: '',

    },
    reducers: {
        clearCreateQuestionState: (state) => {
            state.isCreateQuestionError = false;
            state.isCreateQuestionSuccess = false;
            state.isCreateQuestionFetching = false;
            state.createQuestionErrorMessage = "";
            return state;
        },
        clearUpdateQuestionsOrderState: (state) => {
            state.isUpdateQuestionsOrderError = false;
            state.isUpdateQuestionsOrderSuccess = false;
            state.isUpdateQuestionsOrderFetching = false;
            state.updateQuestionsOrderErrorMessage = "";
            return state;
        },
        clearUpdateQuestionState: (state) => {
            state.isUpdateQuestionError = false;
            state.isUpdateQuestionSuccess = false;
            state.isUpdateQuestionFetching = false;
            state.updateQuestionErrorMessage = "";
            return state;
        },
        clearDeleteQuestionState: (state) => {
            state.isDeleteQuestionError = false;
            state.isDeleteQuestionSuccess = false;
            state.isDeleteQuestionFetching = false;
            state.deleteQuestionErrorMessage = "";
            return state;
        },
        clearGetWorkTypeQuestionsState: (state) => {
            state.isGetWorkTypeQuestionsError = false;
            state.isGetWorkTypeQuestionsSuccess = false;
            state.isGetWorkTypeQuestionsFetching = false;
            state.getWorkTypeQuestionsErrorMessage = "";
            return state;
        },
        clearGetWorkTypeFullQuestionsState: (state) => {
            state.isGetWorkTypeQuestionsError = false;
            state.isGetWorkTypeQuestionsSuccess = false;
            state.isGetWorkTypeQuestionsFetching = false;
            state.getWorkTypeQuestionsErrorMessage = "";
            state.workTypeQuestionsList =[];
            return state;
        },
    },
    extraReducers: {
        [createQuestion.fulfilled]: (state, { payload }) => {
            state.isCreateQuestionFetching = false;
            state.isCreateQuestionSuccess = true;
        },
        [createQuestion.pending]: (state) => {
            state.isCreateQuestionFetching = true;
        },
        [createQuestion.rejected]: (state, { payload }) => {
            state.isCreateQuestionFetching = false;
            state.isCreateQuestionError = true;
            state.createQuestionErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
        },
        [updateQuestion.fulfilled]: (state, { payload }) => {
            state.isUpdateQuestionFetching = false;
            state.isUpdateQuestionSuccess = true;
        },
        [updateQuestion.pending]: (state) => {
            state.isUpdateQuestionFetching = true;
        },
        [updateQuestion.rejected]: (state, { payload }) => {
            state.isUpdateQuestionFetching = false;
            state.isUpdateQuestionError = true;
            state.updateQuestionErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
        },
        [deleteQuestion.fulfilled]: (state, { payload }) => {
            state.isDeleteQuestionFetching = false;
            state.isDeleteQuestionSuccess = true;
        },
        [deleteQuestion.pending]: (state) => {
            state.isDeleteQuestionFetching = true;
        },
        [deleteQuestion.rejected]: (state, { payload }) => {
            state.isDeleteQuestionFetching = false;
            state.isDeleteQuestionError = true;
            state.deleteQuestionErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
        },
        [updateQuestionsOrder.fulfilled]: (state, { payload }) => {
            state.isUpdateQuestionsOrderFetching = false;
            state.isUpdateQuestionsOrderSuccess = true;
        },
        [updateQuestionsOrder.pending]: (state) => {
            state.isUpdateQuestionsOrderFetching = true;
        },
        [updateQuestionsOrder.rejected]: (state, { payload }) => {
            state.isUpdateQuestionsOrderFetching = false;
            state.isUpdateQuestionsOrderError = true;
            state.updateQuestionsOrderErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";;
        },
        [getWorkTypeQuestions.fulfilled]: (state, { payload }) => {
            state.isGetWorkTypeQuestionsFetching = false;
            state.workTypeQuestionsList = payload && payload.data || [];
            state.isGetWorkTypeQuestionsSuccess = true;
            return state;
        },
        [getWorkTypeQuestions.rejected]: (state, { payload }) => {
            state.isGetWorkTypeQuestionsFetching = false;
            state.isGetWorkTypeQuestionsError = true;
            state.getWorkTypeQuestionsErrorMessage = payload && payload.error_description ? payload.error_description : "Invalid Credentials. Please try again!";
        },
        [getWorkTypeQuestions.pending]: (state) => {
            state.isGetWorkTypeQuestionsFetching = true;
        },
    }
});

export const { clearCreateQuestionState, clearGetWorkTypeQuestionsState, clearUpdateQuestionsOrderState, clearDeleteQuestionState, clearUpdateQuestionState } = questionsSlice.actions;

export const questionsSelector = (state) => state.questions;

export default questionsSlice.reducer;